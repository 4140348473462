import styled from 'styled-components';

interface LinkProps {
    readonly fillColor: number;
    readonly selected?: boolean;
    readonly noMargin?: boolean;
}

interface PanelProps {
    readonly vertical?: boolean;
}

interface ProductProps {
    readonly selected?: boolean;
}

export const Wrapper = styled.div`
    width: 100%;
    height: 90vh;
    overflow-y: auto;
`;

export const Legend = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const LegendItem = styled.div<LinkProps>`
    width: 3rem;
    height: 2rem;
    margin: ${(props) => (props.noMargin ? '0 1rem 0 0' : '0.2rem 1rem 0 0')};
    border: 1px solid #666;
    background-color: ${(props) =>
        props.fillColor === 0
            ? '#F35D45'
            : props.fillColor === 1
            ? '#f1e36d'
            : props.fillColor === 2
            ? '#8cf187'
            : 'transparent'};
`;

export const LegendLabel = styled.p`
    font-size: 1.4rem;
    margin-right: 3rem;

    &:last-child {
        margin: 0;
    }
`;

export const PanelsContainer = styled.div`
    margin: 3rem auto;
    width: 95%;
    display: flex;
    min-height: 80vh;
    font-size: 1.6rem;
    justify-content: space-between;
`;

export const Panel = styled.div<PanelProps>`
    display: flex;
    flex-direction: ${(props) => (props.vertical ? 'column' : 'row')};
    padding: ${(props) => (props.vertical ? '0 1rem' : 0)};
    width: ${(props) => (props.vertical ? '20%' : '100%')};
    justify-content: flex-start;
`;

export const Product = styled.div<ProductProps>`
    padding: 0.3rem 1rem;
    margin: 0;
    width: 100%;
    cursor: pointer;
    transition: all 0.4s ease;
    background-color: ${(props) =>
        props.selected
            ? props.theme.secondaryMedium
            : props.theme.primaryLight};
    font-weight: ${(props) => (props.selected ? 700 : 400)};
    ${(props) => (props.selected ? 'transform: translate(0.7rem)' : null)};

    &:hover {
        background-color: ${(props) => props.theme.secondaryMedium};
        transform: translateX(0.7rem);
    }

    &:not(:last-child) {
        border-bottom: 1px solid #ccc;
    }
`;
