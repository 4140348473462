import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Ingredient, IngredientsInterface } from '../../../store/types/types';
import {
    Table,
    TCell,
    THeader,
    TRow
} from '../../../components/UI/Product/Table/ProductTable.styled';
import { Heading2, Heading3 } from '../../../components/UI/Typography';
import { Wrapper } from '../Shared.styled';
import { ProductProps } from '../../../types';

export const Ingredients: React.FC<ProductProps> = (props) => {
    const { product } = props;

    const ingredientsMapHandler = (type: string) => {
        return product.ingredients[
            type as keyof IngredientsInterface
        ]!.ingredients.map((element: Ingredient) => (
            <TRow key={element.name}>
                <TCell>{element.name.replace('&lt;', '<')}</TCell>
                <TCell>
                    {`${element.amount ? element.amount : ''} ${
                        element.unit ? element.unit : ''
                    } ${
                        element.secondaryAmount
                            ? `/ ${element.secondaryAmount} ${
                                  element.secondaryUnit
                                      ? element.secondaryUnit
                                      : element.unit
                              }`
                            : ''
                    }`}
                </TCell>
                <TCell>{`${element.percentage ? element.percentage : ''} ${
                    element.secondaryPercentage
                        ? `/ ${element.secondaryPercentage}`
                        : ''
                }`}</TCell>
            </TRow>
        ));
    };

    const renderEnergyValue = (): JSX.Element[] | null => {
        if (product.energyValue.length) {
            return product.energyValue.map((energyValue) => (
                <React.Fragment key={uuidv4()}>
                    <Heading3>{energyValue.title}</Heading3>
                    <Table>
                        <colgroup>
                            <col span={1} style={{ width: '60%' }} />
                            <col span={1} style={{ width: '40%' }} />
                        </colgroup>
                        <thead>
                            <TRow>
                                <THeader>Wartość energetyczna</THeader>
                                <THeader>{energyValue.dose}</THeader>
                            </TRow>
                        </thead>
                        <tbody>
                            {energyValue.values.map((value) => (
                                <TRow key={uuidv4()}>
                                    <TCell>
                                        {value.name.replace('&lt;', '<')}
                                    </TCell>
                                    <TCell>{`${value.amount} ${value.unit} / ${value.secondaryAmount} ${value.unit}`}</TCell>
                                </TRow>
                            ))}
                        </tbody>
                    </Table>
                </React.Fragment>
            ));
        }

        return null;
    };

    const shouldHaveWiderTable = product.name === 'MyKids Brain';
    const getColgroup =
        product.name === 'ShapeMe' ? (
            <colgroup>
                <col span={1} style={{ width: '60%' }} />
                <col span={1} style={{ width: '40%' }} />
            </colgroup>
        ) : (
            <colgroup>
                <col
                    span={1}
                    style={{ width: shouldHaveWiderTable ? '50%' : '60%' }}
                />
                <col
                    span={1}
                    style={{ width: shouldHaveWiderTable ? '30%' : '20%' }}
                />
                <col span={1} style={{ width: '20%' }} />
            </colgroup>
        );

    return (
        <Wrapper>
            <Heading2>{product.name}</Heading2>
            {product.ingredients.morning &&
            product.ingredients.morning.ingredients.length > 0 ? (
                <React.Fragment>
                    <Heading3>{product.ingredients.morning.name}</Heading3>
                    <Table>
                        <colgroup>
                            <col span={1} style={{ width: '60%' }} />
                            <col span={1} style={{ width: '20%' }} />
                            <col span={1} style={{ width: '20%' }} />
                        </colgroup>
                        <thead>
                            <TRow>
                                <THeader>Składnik</THeader>
                                <THeader>
                                    {product.ingredients.morning.dose}
                                </THeader>
                                <THeader>RWS [%]</THeader>
                            </TRow>
                        </thead>
                        <tbody>{ingredientsMapHandler('morning')}</tbody>
                    </Table>
                </React.Fragment>
            ) : null}
            <Heading3>
                {product.ingredients.night.ingredients.length === 0 &&
                product.ingredients.morning?.ingredients.length === 0
                    ? 'Składniki:'
                    : product.ingredients.day.name}
            </Heading3>
            <Table isWider={shouldHaveWiderTable}>
                {getColgroup}
                <thead>
                    <TRow>
                        <THeader>Składnik</THeader>
                        <THeader>{product.ingredients.day.dose}</THeader>
                        {product.name !== 'ShapeMe' && (
                            <THeader>RWS [%]</THeader>
                        )}
                    </TRow>
                </thead>
                <tbody>{ingredientsMapHandler('day')}</tbody>
            </Table>
            {product.ingredients.night.ingredients.length > 0 ? (
                <>
                    <Heading3>{product.ingredients.night.name}</Heading3>
                    <Table>
                        <colgroup>
                            <col span={1} style={{ width: '60%' }} />
                            <col span={1} style={{ width: '20%' }} />
                            <col span={1} style={{ width: '20%' }} />
                        </colgroup>
                        <thead>
                            <TRow>
                                <THeader>Składnik</THeader>
                                <THeader>
                                    {product.ingredients.night.dose}
                                </THeader>
                                <THeader>RWS [%]</THeader>
                            </TRow>
                        </thead>
                        <tbody>{ingredientsMapHandler('night')}</tbody>
                    </Table>
                </>
            ) : null}
            {renderEnergyValue()}
        </Wrapper>
    );
};
