import React from 'react';
import { ProductProps } from '../../../types';
import { Heading2, Heading3 } from '../../../components/UI/Typography';
import DetailedList from '../../../components/UI/DetailedList/DetailedList';
import { Wrapper } from '../Shared.styled';

export const NeedsApproval: React.FC<ProductProps> = (props) => {
    const { product } = props;
    const permittedProducts = [
        'Vitamin D Natural +',
        'MyKids Vitamin D3',
        'MyKids Brain',
        'OmegaMe'
    ];
    const hasAnotherLabel = product.name === 'VibeMe';

    return (
        <Wrapper>
            <Heading2>{product.name}</Heading2>
            <Heading3>
                {hasAnotherLabel
                    ? 'Przed zastosowaniem suplementu zalecamy konsultację z lekarzem w przypadku:'
                    : permittedProducts.includes(product.name)
                    ? 'Dawkę powinien dobrać indywidualnie lekarz'
                    : 'Stosowanie możliwe jedynie za wyraźną zgodą i pod opieką lekarza'}
            </Heading3>
            {product.needsApproval.map((element) => (
                <DetailedList key={element.name} element={element} />
            ))}
        </Wrapper>
    );
};
