import styled from 'styled-components';

export const Section = styled.div`
    min-width: 33.4%;
    display: flex;
    height: max-content;
    flex-direction: column;
    align-items: center;

    &:not(:last-child) {
        border-right: 1px solid #ccc;
    }
`;

export const ProductExpand = styled.div`
    width: 80%;
    margin: 0.3rem 0;
    padding: 0.3rem 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: ${(props) => props.theme.primaryLight};
    border-bottom: 1px solid #ccc;
`;

export const HorizontalContainer = styled.div`
    display: flex;
`;

export const Paragraph = styled.p`
    padding: 0 1rem;
    word-break: break-word;
    font-size: 1.3rem;
`;
