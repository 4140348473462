import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { connect, ConnectedProps } from 'react-redux';
import { Link, Links } from '../../store/types/types';
import { ProductLinks } from './components';
import {
    Legend,
    LegendItem,
    LegendLabel,
    Wrapper,
    PanelsContainer,
    Panel,
    Product
} from './ProductLinkingView.styled';
import { Heading2 } from '../../components/UI/Typography';

const ProductLinkingView: React.FC<Props> = ({ isKids, links, kidsLinks }) => {
    const [selectedLinks, setSelectedLinks] = React.useState<Link[]>([]);
    const [selectedProduct, setSelectedProduct] = React.useState<number>(-1);

    const productSet: Links[] = isKids ? kidsLinks : links;

    const renderTitle = () =>
        isKids
            ? 'Polecane połączenia produktów - dzieci'
            : 'Polecane połączenia produktów';

    const isProductSelectedFactory = (index: number) => {
        return index === selectedProduct;
    };

    const handleSelectingProductFactory = (index: number) => () => {
        setSelectedLinks(productSet[index].links);
        setSelectedProduct(index);
    };

    return (
        <Wrapper>
            <Heading2>{renderTitle()}</Heading2>
            <Legend>
                <LegendItem fillColor={2} />
                <LegendLabel>Można stosować razem</LegendLabel>
                <LegendItem fillColor={1} />
                <LegendLabel>
                    Można stosować, ale z odstępem czasowym
                </LegendLabel>
                <LegendItem fillColor={0} />
                <LegendLabel>Nie można stosować razem</LegendLabel>
            </Legend>
            <PanelsContainer>
                <Panel vertical>
                    {productSet.map((product: Links, index: number) => (
                        <Product
                            key={uuidv4()}
                            onClick={handleSelectingProductFactory(index)}
                            selected={isProductSelectedFactory(index)}
                        >
                            {product.productName}
                        </Product>
                    ))}
                </Panel>
                <ProductLinks links={selectedLinks} />
            </PanelsContainer>
        </Wrapper>
    );
};

const mapStateToProps = (state: {
    data: {
        links: Links[];
        kidsLinks: Links[];
    };
}) => {
    return {
        links: state.data.links,
        kidsLinks: state.data.kidsLinks
    };
};

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & { isKids?: boolean };

export default connector(React.memo(ProductLinkingView));
