import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { CSSTransition } from 'react-transition-group';
import { connect, ConnectedProps } from 'react-redux';

import { MacroLabel, MacroPanel } from './MacrosView.styled';
import { Macro } from '../../store/types/types';
import {
    IconContainer,
    PanelRow,
    Wrapper
} from '../LocationsView/LocationsView.styled';
import IconChevronRight from '../../components/UI/Icons/IconChevronRight';
import { Heading2 } from '../../components/UI/Typography';

interface Props {
    element: Macro;
}

const MacrosContainer = ({ element }: Props): JSX.Element => {
    const [isActive, setIsActive] = React.useState<boolean>(false);

    const handleToggle = () => setIsActive(!isActive);

    const paragraphFactory = (element: string) => (
        <p key={element.substr(0, 6)}>{element}</p>
    );

    return (
        <MacroPanel>
            <PanelRow onClick={handleToggle}>
                <MacroLabel>{element.name}</MacroLabel>
                <IconContainer active={isActive}>
                    <IconChevronRight
                        size={24}
                        title="Rozwiń"
                        color="#f3596b"
                    />
                </IconContainer>
            </PanelRow>
            <PanelRow>
                <CSSTransition
                    in={isActive}
                    timeout={600}
                    classNames="max-height-medium"
                    unmountOnExit
                >
                    <div>
                        {element.products.heading.map(paragraphFactory)}
                        <br />
                        {element.products.positions.map(paragraphFactory)}
                        <br />
                        {element.products.supplementation ? (
                            <React.Fragment>
                                {element.products.supplementation.title.map(
                                    paragraphFactory
                                )}
                                {element.products.supplementation.entries.map(
                                    paragraphFactory
                                )}
                                <br />
                            </React.Fragment>
                        ) : null}
                        {element.products.details.map(paragraphFactory)}
                    </div>
                </CSSTransition>
            </PanelRow>
        </MacroPanel>
    );
};

const MacrosView = ({ macros }: PropsFromRedux): JSX.Element => {
    return (
        <Wrapper>
            <Heading2>Choroby - co polecamy?</Heading2>
            {macros.map((element: Macro) => (
                <MacrosContainer element={element} key={uuidv4()} />
            ))}
        </Wrapper>
    );
};

const mapStateToProps = (state: { data: { macros: Macro[] } }) => {
    return { macros: state.data.macros };
};

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(React.memo(MacrosView));
