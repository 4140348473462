import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { CSSTransition } from 'react-transition-group';

import { Link } from '../../../store/types/types';
import { Panel, LegendItem } from '../ProductLinkingView.styled';
import {
    Section,
    ProductExpand,
    HorizontalContainer,
    Paragraph
} from './ProductLinks.styled';
import { IconContainer } from '../../LocationsView/LocationsView.styled';
import IconChevronRight from '../../../components/UI/Icons/IconChevronRight';

interface Props {
    links: Link[];
}

const SingleProduct = ({ link }: { link: Link }): JSX.Element => {
    const [isActive, setIsActive] = React.useState<boolean>(false);

    const handleDisplayingDetailsFactory = (link: Link) => () => {
        if (link.details && link.details.length > 0) {
            setIsActive(!isActive);
        }
    };

    return (
        <ProductExpand key={uuidv4()}>
            <HorizontalContainer>
                <LegendItem fillColor={link.canUse} noMargin />
                {link.name}
                {link.details && link.details.length > 0 ? (
                    <IconContainer
                        active={isActive}
                        onClick={handleDisplayingDetailsFactory(link)}
                    >
                        <IconChevronRight
                            size={24}
                            title={'Rozwiń'}
                            color={'#f3596b'}
                        />
                    </IconContainer>
                ) : null}
            </HorizontalContainer>
            <HorizontalContainer>
                <CSSTransition
                    in={isActive}
                    timeout={600}
                    classNames={'max-height-medium'}
                    unmountOnExit
                >
                    <div>
                        {link.details && link.details.length > 0
                            ? link.details!.map((detail: string) => (
                                  <Paragraph key={uuidv4()}>{detail}</Paragraph>
                              ))
                            : null}
                    </div>
                </CSSTransition>
            </HorizontalContainer>
        </ProductExpand>
    );
};

export const ProductLinks = ({ links }: Props): JSX.Element => {
    const renderFilteredLinksByUsage = (canUse: number) => {
        return links
            .filter((product: Link) => product.canUse === canUse)
            .sort((a: Link, b: Link) => a.name.localeCompare(b.name))
            .map((link: Link) => <SingleProduct key={uuidv4()} link={link} />);
    };

    return (
        <React.Fragment>
            <Panel>
                <Section>{renderFilteredLinksByUsage(2)}</Section>
                {links.some((link: Link) => link.canUse === 1) && (
                    <Section>{renderFilteredLinksByUsage(1)}</Section>
                )}
                <Section>{renderFilteredLinksByUsage(0)}</Section>
            </Panel>
        </React.Fragment>
    );
};
